import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const booksData = [
  {
    title: `Learning to Learn and the Navigation of Moods: The Meta-Skill for the Acquisition of Skills`,
    description: `One of the most important skills we need to develop for ourselves and for our children is the skill of learning to learn. But what is this skill? How do we learn to learn? What gets in our way? Gloria Flores explores these questions and claims that learning to learn requires that we first learn to navigate the moods that we routinely fall into during the learning process. `,
    imageName: `tolearn.png`,
    buyUrl: `http://a.co/2RUnNLY`,
    buyText: "Buy on Amazon",
    cta: "Buy",
    author: ["Gloria P Flores "],
    ctaWhere: `Amazon`,
  },
  {
    title: `Conversations For Action and Collected Essays`,
    description: `An exploration into language as the fundamental basis for business, software, and education. Written in the 1980s and later compiled by Maria Flores.  `,
    imageName: `cfa.jpg`,
    buyUrl: `https://www.amazon.com/Conversations-Action-Collected-Essays-Relationships/dp/1478378484/ref=sr_1_1?crid=36Z64793DDPES&dchild=1&keywords=conversations+for+action+and+collected+essays&qid=1597455261&sprefix=conversations+for+action%2Caps%2C207&sr=8-1`,
    cta: "Buy",
    author: ["Fernando Flores"],
    ctaWhere: `Amazon`,
  },
  {
    title: `Understanding Computers and Cognition`,
    description: `Named as the Best Information Science of 1987 by the American Society for Information Science, and recognized by Byte Magazine as one of the all time 20 most influential books on Information Technology.`,
    imageName: `ucc.jpg`,
    buyUrl: `http://www.amazon.com/Understanding-Computers-Cognition-Foundation-Design/dp/0201112973`,
    cta: "Buy",
    author: ["Terry Winograd, Fernando Flores"],
    ctaWhere: `Amazon`,
  },
  {
    title: `Building Trust in Business, Politics, Relationships and Life`,
    description: `"This is a sensible and inspirational guide for business leaders who want to build vibrant, living enterprises of the future." - Lynn Johnson, Managing Director of Membership, American Management Association. Oxford University Press, 2001.`,
    imageName: `bt.jpg`,
    buyUrl: `http://www.amazon.com/Building-Trust-Business-Politics-Relationships/dp/0195161114/ref=sr_1_1?ie=UTF8&s=books&qid=1247599105&sr=1-1`,
    cta: "Buy",
    author: ["Robert C. Solomon, Fernando Flores"],
    ctaWhere: `Amazon`,
  },
  {
    title: `Disclosing New Worlds`,
    description: `Described by the Financial Times as a "brave attempt to reformulate the relationship between democratic rights and economic progress in an age when the triumphalism of technological advance masks an unconfident vision of the future."`,
    imageName: `dnw.jpg`,
    buyUrl: `http://www.amazon.com/Disclosing-New-Worlds-Entrepreneurship-Cultivation/dp/0262692244/ref=sr_1_1?ie=UTF8&s=books&qid=1247604743&sr=1-1`,
    cta: "Buy",
    author: ["Hubert L. Dreyfus, Fernando Flores, Charles Spinoza"],
    ctaWhere: `Amazon`,
  },
  {
    title: `Javi's Opportunity Manual Soft Cover: A Kid's Guide To Making Things Happen`,
    description: `Through her reflections, Javi shows children how language and conversations – the simple and taken-for-granted acts of empathetic listening and active speaking – can make opportunity happen.`,
    imageName: "javi.jpg",
    author: ["Maria Flores Letelier, J.L.Flores"],
    cta: "Buy",
    buyUrl: `https://www.amazon.com/Javis-Opportunity-Manual-Soft-Cover-ebook/dp/B0752Y49WX/ref=sr_1_1?crid=1G8HIW1MQ0ED4&dchild=1&keywords=maria+flores+letelier&qid=1595996931&sprefix=maria+letelier%2Caps%2C232&sr=8-1`,
  },
  {
    title: `Entrepreneurship and Wired Life`,
    description: `Work in the wake of careers.`,
    imageName: `demos.jpg`,
    cta: "Read",
    ctaWhere: "Google",
    author: ["Fernando Flores, John Gray"],
    buyUrl: `https://books.google.com.mx/books?id=RlRcSHlYgVEC&pg=PA18&lpg=PA18&dq=john+gray+fernando+flores+end+of+career&source=bl&ots=ZgS89oBeBB&sig=AHZzwbhjwdWQvIdRMZ6It4oor5Q&hl=es&ei=Xf6eSr2_BpKEswOdmKSRDg&sa=X&oi=book_result&ct=result&resnum=1#v=onepage&q&f=false`,
  },
  {
    title: `Business, Technological, and Social Dimensions of Computer Games: Multidisciplinary Developments`,
    description: `Chapter 25: Pluralistic Coordination`,
    imageName: `./portada2.png`,
    buyUrl: `/pdf/flores_chap_cunha_book.pdf`,
    author: ["Fernando Flores, Peter J. Denning, Gloria P Flores"],
    cta: "Read",
  },

  //{
  //   title: ``,
  //   description: ``,
  //   imageName: ``,
  //   buyUrl: ``,
  // },
]
const PublicationsPage = () => (
  <Layout>
    <SEO title="Books - Papers - Other Publications" />
    <div
      class="maxWidth pagePadding"
      style={{
        marginTop: "78px",
      }}
    >
      <div style={{ marginBottom: "65px", textAlign: "center" }}>
        <span class="pageTitle2">Books </span>
        <span class=" pageSub2">& Publications</span>
      </div>
      <div class="columns is-multiline is-gapless">
        {booksData.map(book => (
          // <div style={{ marginBottom: "40x" }}>
          <>
            <div class="bookImageDisplay centered column is-one-fifth">
              <img
                width="150"
                alt="Book cover"
                // height="120"/
                src={`/images/books/${book.imageName} `}
              />
            </div>
            <div class="column is-four-fifths">
              <div style={{ marginBottom: "16px" }} class="pubTitle">
                {book.title}
              </div>
              <div style={{ marginBottom: "16px" }} class="pubAuthor">
                by {book.author}
              </div>
              <div style={{ marginBottom: "16px" }} class="pubDesc">
                {book.description}
              </div>
              <div class="centered" style={{ marginTop: "30px" }}>
                {book.cta === "Buy" && (
                  <button class="buyButton">
                    <Link to={book.buyUrl} target="_blank">
                      Buy
                    </Link>
                  </button>
                )}
                {book.cta === "Read" && (
                  <button class="readButton">
                    <Link to={book.buyUrl} target="_blank">
                      Read
                    </Link>
                  </button>
                )}
              </div>
            </div>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
              class="topLine"
            >
              &nbsp;
            </div>
          </>
        ))}
      </div>
    </div>
    <div style={{ marginTop: "40px", marginBottom: "0px" }} class="topLine">
      &nbsp;
    </div>
  </Layout>
)

export default PublicationsPage
